.cui-app-main.mat-typography {
  .mat-form-field-wrapper {
    padding-bottom: 0;
    width: 100%;

    .mat-form-field-subscript-wrapper {
      position: relative;
    }
  }

  // Mat-select
  .mat-mdc-option {
    min-height: var(--mat-form-field-container-height);
    padding-left: var(--cui-medium-space);
    padding-right: var(--cui-medium-space);
  }
}
