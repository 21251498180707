.ag-row {
  cursor: pointer;
}

.ag-standard-button.ag-filter-apply-panel-button:not(:disabled) {
  font-family: var(--cui-font-h6-font-family);
  font-size: var(--cui-font-h6-size);
  line-height: var(--cui-font-h6-line-height);
  font-weight: var(--cui-font-h6-weight);
  padding-right: var(--cui-small-space);
  text-transform: var(--cui-buttons-text-transform);
  letter-spacing: var(--cui-font-buttons-letter-spacing-large);
  color: var(--cui-color-cta);
  cursor: pointer;

  &:active {
    color: var(--cui-color-active-button);
  }

  &:focus {
    box-shadow: none;
  }
}

.xs-icon {
  mat-icon.mat-icon {
    color: var(--cui-color-breadcrumbs);
    font-size: var(--cui-small-icon-font-size);
    width: var(--cui-small-icon-width);
    height: var(--cui-small-icon-height);
  }
}

.mat-typography.cui-app-main {
  .ag-standard-button:disabled {
    cursor: default;
  }
}

.ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper.ag-checked:after {
  background-color: var(--color-white);
}
