@use 'sass:math';
@use 'responsive';

$max-columns: 3;

@mixin column($columns) {
  $multiplier: math.div($columns, $max-columns);

  width: math.percentage($multiplier);

  @include responsive.media-cui-lt-xs {
    width: 100%;
  }

  @include responsive.media-cui-gt-xs {
    & + & {
      margin-left: 30px;
    }
  }
}

@mixin horizontal-container() {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

@mixin horizontal-space-between() {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@mixin horizontal-block-base() {
  max-height: calc(100vh - 165px);
}

@mixin max-block-height() {
  height: calc(100vh - 165px);
}

@mixin horizontal-block-fw() {
  @include max-block-height();

  @include responsive.media-sm {
    flex: 0 1 100%;
  }

  @include responsive.media-sm {
    flex: 0 1 100%;
  }

  @include responsive.media-md {
    flex: 0 1 100%;
  }

  @include responsive.media-lg {
    flex: 0 1 100%;
    width: 100%;
  }

  @include responsive.media-xl {
    flex: 0 1 100%;
    width: 100%;
  }
}

@mixin horizontal-block-hw() {
  @include horizontal-block-base();

  @include responsive.media-gt-md {
    flex: 0 0 calc(50vw);
    margin-right: 30px;
  }
}

@mixin horizontal-block-3rdw() {
  @include horizontal-block-base();

  @include responsive.media-sm {
    flex: 0 1 100%;
  }

  @include responsive.media-sm {
    flex: 0 1 100%;
  }

  @include responsive.media-md {
    flex: 0 1 100%;
  }

  @include responsive.media-gt-md {
    flex: 0 1 math.div(responsive.$bp-lg, 3);
  }
}
