.mat-mdc-button .mat-mdc-button-touch-target,
.mat-mdc-unelevated-button .mat-mdc-button-touch-target,
.mat-mdc-raised-button .mat-mdc-button-touch-target,
.mat-mdc-outlined-button .mat-mdc-button-touch-target {
  height: var(--mat-form-field-container-height);
}

.cui-app-main.mat-typography {
  .mat-mdc-menu-content,
  .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
    font-family: var(--cui-font-buttons-font-family);
    font-size: var(--cui-font-buttons-size-large);
    font-weight: var(--cui-font-buttons-weight);
    line-height: var(--cui-font-buttons-line-height-large);
    min-width: var(--cui-size-buttons-min-width);
    min-height: auto;
  }

  .mat-mdc-menu-item {
    min-height: 2rem;
  }

  .mat-mdc-menu-panel {
    min-width: 0;
  }

  mat-button-toggle-group {
    border-radius: var(--cui-size-buttons-border-radius);
  }

  mat-button-toggle-group mat-button-toggle {
    .mat-button-toggle-button {
      font-family: var(--cui-font-buttons-font-family);
      font-size: var(--cui-font-buttons-size-large);
      font-weight: var(--cui-font-buttons-weight);
      line-height: var(--cui-font-buttons-line-height-large);
      min-width: var(--cui-size-buttons-min-width);
      height: var(--cui-size-buttons-height-large);
    }
  }
}
