.cui-app-main.mat-typography {
  --mat-datepicker-calendar-text-size: var(--cui-font-p-size);
  --mat-datepicker-calendar-period-button-text-size: var(--cui-font-p-size);
  --mdc-text-button-label-text-size: var(--cui-font-p-size);

  --mat-datepicker-calendar-text-font: var(--cui-font-family);
  --mat-datepicker-calendar-period-button-text-font: var(--cui-font-family);
  --mdc-text-button-label-text-font: var(--cui-font-family);

  .mat-calendar-header {
    padding: var(--cui-small-space);
    padding-bottom: 0;
  }

  .mat-calendar-body {
    min-width: 14rem;
  }

  .mat-datepicker-content .mat-calendar {
    width: 18.5rem;
    height: 22.125rem;
  }

  .mat-calendar-content {
    padding: var(--cui-small-space);
    padding-top: 0;
    outline: none;
  }

  .mat-calendar-table-header th {
    padding: 0;
    padding-bottom: var(--cui-small-space);
  }

  .mat-calendar-previous-button::after,
  .mat-calendar-next-button::after {
    margin: 0.781rem;
  }
}
