:root {
  // Material and ag variables

  --mdc-outlined-text-field-input-text-color: var(--cui-color-body-text);
  --mat-standard-button-toggle-selected-state-background-color: var(--cui-color-cta);
  --mat-standard-button-toggle-divider-color: var(--cui-color-cta);
  --mat-form-field-container-text-size: var(--cui-font-p-size);
  --mat-form-field-container-height: 2.75rem; //44px
  --mat-form-field-container-vertical-padding: 0.625rem;
  --mat-form-field-filled-with-label-container-padding-top: 0.625rem;
  --mat-form-field-filled-with-label-container-padding-bottom: 0.625rem;
  --mat-form-field-container-text-line-height: 1.5rem;
  --mdc-outlined-text-field-outline-color: var(--cui-color-input-outline-color);
  --mat-select-trigger-text-size: var(--cui-font-p-size);
  --mat-select-enabled-trigger-text-color: var(--cui-color-body-text);
  --mat-option-label-text-color: var(--cui-color-body-text);
  --mat-option-label-text-size: var(--cui-font-p-size);
  --mat-form-field-subscript-text-line-height: var(--cui-font-small-line-height);
  --mdc-outlined-text-field-label-text-color: var(--cui-color-label-text-color);
  --mdc-outlined-text-field-hover-label-text-color: var(--cui-color-label-hover-text-color);
  --mdc-outlined-text-field-disabled-label-text-color: var(--cui-color-label-disabled-text-color);

  --mat-expansion-header-expanded-state-height: 2rem;
  --mat-expansion-header-collapsed-state-height: 30px;
  --mat-expansion-header-text-font: var(--cui-font-family);
  --mat-expansion-header-text-size: var(--cui-font-p-size);
  --mat-expansion-header-text-weight: 500;

  --mdc-icon-button-icon-size: var(--cui-icon-font-size);
  --mdc-icon-button-state-layer-size: var(--cui-icon-width);

  --mdc-checkbox-state-layer-size: var(--cui-icon-width);

  .mdc-switch {
    --mdc-switch-handle-height: 1.25rem;
    --mdc-switch-handle-shape: 0.625rem;
    --mdc-switch-handle-width: 1.25rem;
    --mdc-switch-selected-icon-size: 1.125rem;
    --mdc-switch-track-height: 1rem;
    --mdc-switch-track-shape: 0.438rem;
    --mdc-switch-track-width: 2.5rem;
    --mdc-switch-unselected-icon-size: 1.125rem;
    --mdc-switch-state-layer-size: var(--cui-icon-width);
  }
}

html body .mat-mdc-slide-toggle {
  --mat-switch-unselected-handle-size: 1.25rem;
  --mat-switch-selected-handle-size: 1.25rem;
  --mat-switch-pressed-handle-size: 1.25rem;
  --mat-switch-with-icon-handle-size: 1.25rem;
}
