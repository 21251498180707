@use 'configs/colors.scss';
@use 'configs/typography.scss';
@use 'configs/responsive.scss';
@use 'material/main.scss';
@use 'configs/common.scss';
@use 'configs/ag-grid-table.scss';
@use 'configs/material-variables.scss';
@use 'components/cui-card.scss';
@use 'components/cui-app-container.scss';
@use 'components/scrollbar.scss';
@use 'components/cui-input.scss';
@use 'components/cui-button-dropdown.scss';
@use 'components/cui-button-dropdown-action.scss';
@use 'components/cui-search-select.scss';

@import '@ng-select/ng-select/themes/material.theme.css';

.cui-app-main.mat-typography {
  .hidden {
    display: none;
    visibility: hidden;
  }

  .zero-height {
    height: 0;
    max-height: 0;
  }
}
